// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latest-tsx": () => import("./../../../src/pages/latest.tsx" /* webpackChunkName: "component---src-pages-latest-tsx" */),
  "component---src-pages-posts-react-tsx": () => import("./../../../src/pages/posts/react.tsx" /* webpackChunkName: "component---src-pages-posts-react-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-snippets-tsx": () => import("./../../../src/pages/snippets.tsx" /* webpackChunkName: "component---src-pages-snippets-tsx" */)
}

