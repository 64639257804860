import React, { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { Helmet } from "react-helmet";
import { ThemeProvider as StyledTheme } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider as MaterialTheme,
  StylesProvider,
} from "@material-ui/core/styles";

import favicon from "src/images/favicon.png";

import GlobalStyles from "./GlobalStyles";
import Initialization from "./effects/Initialization";
import SyncViewportDimensions from "./effects/SyncViewportDimensions";
import SyncScrollPosition from "./effects/SyncScrollPosition";
import SyncLocation from "./effects/SyncLocation";
import MobileDetection from "./effects/MobileDetection";
import PageChange from "./effects/PageChange";

import lightTheme from "./theme/light";
import darkTheme from "./theme/dark";
import ThemeChoice from "src/state/ThemeChoice";

export default function AppSetup({
  children,
  location,
}: {
  children: ReactNode;
  location: string;
}) {
  const themeChoice = useRecoilValue(ThemeChoice);
  const theme = themeChoice === "dark" ? darkTheme : lightTheme;
  return (
    <StylesProvider injectFirst>
      <MaterialTheme theme={theme}>
        <StyledTheme theme={theme}>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="keywords"
              content="Web, Software, Javascript, Typescript"
            />
            <meta
              name="description"
              content="Software development and general rants."
            />
            <link rel="icon" href={favicon} />
            <title>Jonathan Newman</title>
          </Helmet>
          <CssBaseline />
          <GlobalStyles />
          <Initialization />
          <SyncViewportDimensions />
          <SyncScrollPosition />
          <SyncLocation location={location} />
          <MobileDetection />
          <PageChange />
          {children}
        </StyledTheme>
      </MaterialTheme>
    </StylesProvider>
  );
}
