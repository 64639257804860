import React from "react";

import Link from "./Link";
import First from "./name/First";
import Middle from "./name/Middle";
import Last from "./name/Last";
import Domain from "./name/Domain";

export default function Logo(props: any) {
  return (
    <Link {...props}>
      <First>Jon</First>
      <Middle>B</Middle>
      <Last>Newman</Last>
      <Domain>.dev</Domain>
    </Link>
  );
}
