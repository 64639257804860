import { useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";

import ScrollPosition from "src/state/ScrollPosition";

export default function SyncScrollPosition() {
  const setScrollPosition = useSetRecoilState(ScrollPosition);
  const onScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, [setScrollPosition]);
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    onScroll();
    return () => window.removeEventListener("scroll", onScroll);
  }, [setScrollPosition, onScroll]);
  return null;
}
