import styled from "styled-components";
import { Link } from "gatsby";

export default styled(Link)`
  text-decoration: none;
  grid-area: logo;
  max-width: 100%;
  font-weight: bold;
  font-size: 25px;
  place-self: center;
  color: ${({ theme }) => theme.palette.text.primary};
  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
