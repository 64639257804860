import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useMediaQuery } from "@material-ui/core";

import IsMobile from "src/state/IsMobile";

export default function MobileDetection() {
  const setIsMobile = useSetRecoilState(IsMobile);
  const mobileSized = useMediaQuery(`(max-width: 760px)`);
  useEffect(() => setIsMobile(mobileSized), [mobileSized, setIsMobile]);
  return null;
}
