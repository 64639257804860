import styled, { css } from "styled-components";

const fixedStyle = css`
  position: fixed;
  top: 0;
  box-shadow: 0 -17px 25px 0px rgba(0, 0, 0, 0.6);
`;

const absoluteStyle = css`
  position: absolute;
  top: 100%;
  box-shadow: none;
`;

export default styled.div<{ fixed: boolean }>`
  ${({ fixed }) => (fixed ? fixedStyle : absoluteStyle)}
  z-index: 10;
  width: 100%;
  transition: box-shadow 0.3s ease;
  padding: ${({ theme }) => `${theme.spacing(2)}px 0`};
  border-top: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  background: ${({ theme }) => theme.palette.background.paper};
`;
