import React from "react";
import CopyrightIcon from "@material-ui/icons/Copyright";
import Container from "./Container";
import Copyright from "./Copyright";
import RightsNotice from "./RightsNotice";

function CopyrightNotice() {
  return (
    <Container>
      <Copyright>
        <CopyrightIcon />
        2021-present Jonathan Newman
      </Copyright>
      <RightsNotice>All Rights Reserved</RightsNotice>
    </Container>
  );
}

export default CopyrightNotice;
