import { useCallback, useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";

import IsResizingViewport from "src/state/IsResizingViewport";
import ViewportDimensions, {
  getWidth,
  getHeight,
} from "src/state/ViewportDimensions";

/**
 * Save and sync viewport dimensions so that various layout
 * dimensions/components are computed correctly.
 */
function SyncViewportDimensions() {
  const resizeTimeout = useRef<NodeJS.Timeout>();
  const setViewportDimensions = useSetRecoilState(ViewportDimensions);
  const setIsResizingViewport = useSetRecoilState(IsResizingViewport);
  const onResize = useCallback(() => {
    setIsResizingViewport(true);
    setViewportDimensions({
      width: getWidth(),
      height: getHeight(),
    });
    resizeTimeout.current && clearTimeout(resizeTimeout.current);
    resizeTimeout.current = setTimeout(() => setIsResizingViewport(false), 100);
  }, [setIsResizingViewport, setViewportDimensions]);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [setViewportDimensions, setIsResizingViewport, onResize]);
  return null;
}

export default SyncViewportDimensions;
