import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButtonProps } from "@material-ui/core";
import IconButton from "./IconButton";

import Container from "./Container";

export default function DrawerButton({ onClick }: IconButtonProps) {
  return (
    <Container>
      <IconButton aria-label="delete" onClick={onClick}>
        <MenuIcon fontSize="inherit" />
      </IconButton>
    </Container>
  );
}
