import styled from "styled-components";

export default styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  grid-row-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  grid-template-areas:
    ". copyright"
    ". rights";
  place-items: start end;

  @media all and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    place-self: start;
    place-items: start;
  }
`;
