import { atom } from "recoil";

export function getWidth() {
  return document
    ? Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
    : 0;
}

export function getHeight() {
  return document
    ? Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )
    : 0;
}

/**
 * Stores the viewport dimensions
 */
export default atom<{
  width: number;
  height: number;
}>({
  key: "ViewportDimensions",
  default: {
    width: 0,
    height: 0,
  },
});
