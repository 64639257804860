import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import NavTop from "src/state/NavTop";
import ViewportDimensions from "src/state/ViewportDimensions";

import Logo from "src/components/common/Logo";
import Container from "./Container";
import Grid from "./Grid";
import Navigation from "./Navigation";

export default function Header() {
  const layoutDimensions = useRecoilValue(ViewportDimensions);
  const setNavTop = useSetRecoilState(NavTop);
  const [navContainer, setNavContainer] = useState<HTMLDivElement>();
  const syncNavTop = useCallback((el) => setNavContainer(el), []);

  useEffect(() => {
    if (navContainer) {
      setNavTop(navContainer.offsetHeight);
    }
  }, [setNavTop, navContainer, layoutDimensions]);

  return (
    <Container ref={syncNavTop} style={{ backgroundPosition: `center top` }}>
      <Grid>
        <Logo to="/" />
      </Grid>
      <Navigation />
    </Container>
  );
}
