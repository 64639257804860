import { createTheme, ThemeOptions } from "@material-ui/core/styles";

import themeBase from "./themeBase";

const theme: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#fa3232",
    },
    secondary: {
      main: "#32FAFA",
    },
    background: {
      default: "#111111",
      paper: "#191919",
    },
  },
  ...themeBase,
};

export default createTheme(theme);
