import React, { ReactNode } from "react";
import { RecoilRoot } from "recoil";

import AppSetup from "../AppSetup";
import Scene from "./Scene";
import Grid from "./Grid";
import Header from "./Header";
import Footer from "./Footer";

export default function Layout({
  children,
  location,
}: {
  children: ReactNode;
  location: string;
}) {
  return (
    <RecoilRoot>
      <AppSetup location={location}>
        <Scene />
        <Grid>
          <Header />
          {children}
          <Footer />
        </Grid>
      </AppSetup>
    </RecoilRoot>
  );
}
