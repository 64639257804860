import React from "react";
import { useSpring } from "@react-spring/core";

import Background from "./Background";
import Content from "./Content";
import CopyrightNotice from "./CopyrightNotice";
import ThemeToggle from "./ThemeToggle";

export default function Footer() {
  const contentSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { clamp: true, duration: 1000 },
  });
  return (
    <Background style={contentSpring}>
      <Content>
        <CopyrightNotice />
        <ThemeToggle />
      </Content>
    </Background>
  );
}
