import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import Location from "src/state/Location";

export default function SyncLocation({ location }: { location: string }) {
  const setLocation = useSetRecoilState(Location);
  useEffect(() => {
    setLocation(location);
  }, [location, setLocation]);
  return null;
}
