import styled from "styled-components";
import { Container } from "@material-ui/core";
import { animated } from "@react-spring/web";

export default animated(styled(Container)`
  display: grid;
  grid-column-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  transform-origin: 50% 50%;
  grid-template-columns: auto 1fr;
  grid-template-areas: "left-menu right-menu";
`);
