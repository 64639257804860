import { useTheme } from "@material-ui/core";
import { useEffect } from "react";

declare global {
  interface Window {
    theme: any;
  }
}

export default function Initialization() {
  const theme = useTheme();
  useEffect(() => {
    window.theme = theme;
    document.body.style.opacity = "1";
  }, [theme]);
  return null;
}
