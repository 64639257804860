import styled from "styled-components";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";

import buttonStyle from "./buttonStyle";

interface ListItemInterface extends ListItemProps {
  component: any;
  to: string;
  active?: boolean;
}

export default styled(ListItem)<ListItemInterface>`
  white-space: nowrap;
  ${buttonStyle}
`;
