import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { useRecoilValue } from "recoil";
import { useTheme } from "@material-ui/core";
import * as three from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";

import ViewportDimensions from "src/state/ViewportDimensions";
import { isWebGLAvailable } from "./helpers";
import Canvas from "./Canvas";

function Scene() {
  const shouldRender = process.env.NODE_ENV === "development";
  const theme = useTheme();
  const { width, height } = useRecoilValue(ViewportDimensions);

  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const mouseRef = useRef<HTMLCanvasElement | null>(null);
  const canvasCallback = useCallback((canvas: HTMLCanvasElement) => {
    mouseRef.current = canvas;
    setCanvas(canvas);
  }, []);

  const renderer = useMemo(
    () =>
      canvas ? new three.WebGLRenderer({ canvas, antialias: true }) : null,
    [canvas]
  );
  const scene = useMemo(() => new three.Scene(), []);
  const camera = useMemo(() => {
    if (!width || !height) {
      return null;
    }
    const camera = new three.PerspectiveCamera(55, width / height, 0.1, 1000);
    camera.name = "MainCamera";
    return camera;
  }, [width, height]);

  const entity = useMemo(() => {
    return new three.Mesh(
      new three.DodecahedronGeometry(1),
      new three.MeshBasicMaterial({
        color: theme.palette.primary.dark,
        // envMap: new three.TextureLoader().load("/gradient.jpg"),
      })
    );
  }, []);

  useEffect(
    function setupScene() {
      if (camera && canvas && isWebGLAvailable()) {
        scene.add(entity);
        scene.add(camera);
      }
    },
    [scene, entity, camera, canvas]
  );

  const controls = useRef<OrbitControls>();
  const rotation = useRef<gsap.core.Tween>();
  useEffect(
    function render() {
      if (camera && renderer && width && height) {
        camera.position.z = 5;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        if (controls.current) {
          controls.current.dispose();
        }
        controls.current = new OrbitControls(camera, renderer.domElement);
        controls.current.listenToKeyEvents(renderer.domElement);
        controls.current.enablePan = false;

        // Initiate object rotation
        if (rotation.current) {
          rotation.current.kill();
        }
        rotation.current = gsap.to(entity.rotation, {
          y: Math.PI * 2,
          duration: 15,
          repeat: -1,
          onUpdate: () => {
            controls.current && controls.current.update();
            renderer.render(scene, camera);
          },
          ease: "none",
        });
      }
    },
    [camera, scene, renderer, width, height]
  );

  if (!shouldRender) {
    return null;
  }
  return <Canvas ref={canvasCallback} />;
}

export default Scene;
