import { atom } from "recoil";

/**
 * Stores flag indicating whether or not the viewport
 * is being resized by the user
 */
export default atom<boolean>({
  key: "isResizingViewport",
  default: false,
});
