import styled from "styled-components";
import Container from "@material-ui/core/Container";

export default styled(Container)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${({ theme }) => `${theme.spacing(3)}px`};
  grid-row-gap: ${({ theme }) => `${theme.spacing(3)}px`};
  width: 100%;
  height: 100%;
  place-items: end start;
  grid-template-areas: "logo .";
`;
