import { Container } from "@material-ui/core";
import styled from "styled-components";

export default styled(Container).attrs({ component: "div" })`
  min-height: 200px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "theme . copyright";
  padding-top: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  grid-gap: ${({ theme }) => `${theme.spacing(2)}px`};

  @media all and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "theme"
      "copyright";
  }
`;
