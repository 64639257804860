import React from "react";
import { useRecoilState } from "recoil";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

import ThemeChoice from "src/state/ThemeChoice";
import Container from "./Container";
import Box from "./Box";

export default function ThemeToggle() {
  const [themeChoice, setThemeChoice] = useRecoilState(ThemeChoice);

  function toggleTheme() {
    setThemeChoice(themeChoice === "dark" ? "light" : "dark");
  }

  return (
    <Container>
      <Box>
        <Typography>
          {themeChoice === "light" ? "Light" : "Dark"} theme
        </Typography>
        <Switch
          checked={themeChoice === "dark"}
          onChange={toggleTheme}
          color="primary"
          name="theme"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </Box>
    </Container>
  );
}
