import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  MouseEvent,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Link as GatsbyLink } from "gatsby";
import { useSpring, useTransition, config } from "@react-spring/core";
import { animated } from "@react-spring/web";
import HomeIcon from "@material-ui/icons/Home";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useKey, useClickAway } from "react-use";

import NavHeight from "src/state/NavHeight";
import NavTop from "src/state/NavTop";
import ScrollPosition from "src/state/ScrollPosition";
import ViewportDimensions from "src/state/ViewportDimensions";
import IsMobile from "src/state/IsMobile";
import DrawerIsOpen from "src/state/DrawerIsOpen";
import Location from "src/state/Location";

import Logo from "src/components/common/Logo";
import Container from "./Container";
import Grid from "./Grid";
import RightSide from "./RightSide";
import LeftSide from "./LeftSide";
import Button from "./Button";
import DrawerButton from "./DrawerButton";
import DrawerGrid from "./DrawerGrid";
import Dropdown from "./Dropdown";
import ListItem from "./ListItem";
import LogoContainer from "./LogoContainer";

export default function Navigation() {
  const location = useRecoilValue(Location);
  const [drawerIsOpen, setDrawerIsOpen] = useRecoilState(DrawerIsOpen);
  function openDrawer() {
    setDrawerIsOpen(true);
  }
  function closeDrawer() {
    setDrawerIsOpen(false);
  }

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const toggleMenu = useCallback(
    (event) => {
      setMenuIsOpen(!menuIsOpen);
      event.stopPropagation();
      event.preventDefault();
    },
    [setMenuIsOpen, menuIsOpen]
  );
  const closePosts = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen]);

  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, closePosts);
  useKey("Escape", closePosts);
  useEffect(closePosts, [closePosts, location]);

  const layoutDimensions = useRecoilValue(ViewportDimensions);
  const isMobile = useRecoilValue(IsMobile);
  const setNavHeight = useSetRecoilState(NavHeight);
  const [navContainer, setNavContainer] = useState<HTMLDivElement>();
  const syncNavHeight = useCallback((el) => setNavContainer(el), []);
  const navTop = useRecoilValue(NavTop);
  const scrollPosition = useRecoilValue(ScrollPosition);

  const isFixed = useMemo(
    () => scrollPosition >= navTop,
    [navTop, scrollPosition]
  );

  const logoTransition = useTransition(isFixed, {
    from: { width: "0px", marginRight: "0px", opacity: 0 },
    enter: { width: "55px", marginRight: "8px", opacity: 1 },
    leave: { width: "0px", marginRight: "0px", opacity: 0, delay: 400 },
    config: { ...config.gentle, clamp: true },
  });

  const menuTransition = useTransition(menuIsOpen, {
    from: { opacity: 0, transform: `translateY(-16px)` },
    enter: { opacity: 1, transform: `translateY(0px)` },
    leave: { opacity: 0, transform: `translateY(0px)` },
    config: { ...config.stiff, clamp: true },
  });

  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 400,
  });

  useEffect(() => {
    if (navContainer) {
      setNavHeight(navContainer.offsetHeight);
    }
  }, [setNavHeight, navContainer, layoutDimensions]);

  return !isMobile ? (
    <Container ref={syncNavHeight} fixed={isFixed}>
      <Grid style={spring} placement={isFixed ? "fixed" : "normal"}>
        <LeftSide>
          {logoTransition(
            (style, item) =>
              item && (
                <animated.div style={style}>
                  <Logo />
                </animated.div>
              )
          )}
          <Button
            startIcon={<HomeIcon />}
            variant="text"
            component={GatsbyLink}
            to="/"
          >
            Home
          </Button>
        </LeftSide>
        <RightSide>
          <Button
            variant="text"
            component={GatsbyLink}
            to="/latest/"
            partiallyActive={true}
            activeClassName="active"
          >
            Latest
          </Button>
          <div style={{ position: "relative" }} ref={ref}>
            <Button
              variant="text"
              component={GatsbyLink}
              to="/posts/"
              onClick={toggleMenu}
              active={menuIsOpen}
              partiallyActive={true}
              activeClassName="active"
            >
              Posts
            </Button>
            {menuTransition(
              (style, item) =>
                item && (
                  <Dropdown
                    style={style}
                    onClick={(event: MouseEvent) => event.stopPropagation()}
                  >
                    <ListItem button component={GatsbyLink} to="/posts/react/">
                      React
                    </ListItem>
                  </Dropdown>
                )
            )}
          </div>
          <Button variant="text" component={GatsbyLink} to="/snippets/">
            Snippets
          </Button>
        </RightSide>
      </Grid>
    </Container>
  ) : (
    <>
      <DrawerButton onClick={openDrawer} />
      <SwipeableDrawer
        anchor="right"
        open={drawerIsOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <div>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <DrawerGrid>
            <Button
              startIcon={<HomeIcon />}
              variant="text"
              component={GatsbyLink}
              to="/"
            >
              Home
            </Button>
            <Button variant="text" component={GatsbyLink} to="/latest/">
              Latest
            </Button>
            <Button variant="text" component={GatsbyLink} to="/posts/">
              Posts
            </Button>
            <Button variant="text" component={GatsbyLink} to="/snippets/">
              Snippets
            </Button>
          </DrawerGrid>
        </div>
      </SwipeableDrawer>
    </>
  );
}
