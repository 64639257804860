import { useEffect, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Location from "src/state/Location";
import DrawerIsOpen from "src/state/DrawerIsOpen";
import NavTop from "src/state/NavTop";
import ScrollPosition from "src/state/ScrollPosition";

export default function PageChange() {
  const setDrawerIsOpen = useSetRecoilState(DrawerIsOpen);
  const navTop = useRecoilValue(NavTop);
  const location = useRecoilValue(Location);
  const scrollPosition = useRecoilValue(ScrollPosition);
  const previousLocation = useRef<string>();

  // animate scroll to nav on page change
  useEffect(() => {
    if (previousLocation.current !== location && scrollPosition > navTop) {
      window.scroll({
        top: navTop,
        behavior: "smooth",
      });
    }
    previousLocation.current = location;
  }, [navTop, location, scrollPosition]);

  // close the mobile drawer
  useEffect(() => setDrawerIsOpen(false), [location, setDrawerIsOpen]);

  return null;
}
