import { Box } from "@material-ui/core";
import styled from "styled-components";

export default styled(Box).attrs({ component: "label" })`
  display: grid;
  grid-template-columns: 1fr auto;
  place-items: center end;
  grid-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  cursor: pointer;
  user-select: none;
  padding: ${({ theme }) =>
    `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px ${theme.spacing(2)}px`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  background: ${({ theme }) => theme.palette.background.default};

  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
