import { css } from "styled-components";

const activeStyle = css`
  && {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.primary.main)};
  }
`;

export default css<{ active?: boolean }>`
  white-space: nowrap;
  &[aria-current],
  &.active {
    ${activeStyle}
  }
  ${({ active }) => active && activeStyle}
`;
