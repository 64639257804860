import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html, body {
    width: 100%;
    min-height: 100%;
  }

  main {
    position: relative;
  }
`;
