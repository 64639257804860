import { ThemeOptions } from "@material-ui/core";

const themeBase: Partial<ThemeOptions> = {
  props: {
    MuiContainer: {
      maxWidth: "md",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 3,
  },
};

export default themeBase;
