import { List } from "@material-ui/core";
import { animated } from "@react-spring/web";
import styled from "styled-components";

export default animated(styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
  background: ${({ theme }) => theme.palette.background.paper};
  border: ${({ theme }) => `1px solid ${theme.palette.divider}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  box-shadow: ${({ theme }) => theme.shadows[4]};
  min-width: 100%;
  transform-origin: 50% 50%;
`);
