import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import themeBase from "./themeBase";

const theme: ThemeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#fa3232",
    },
    secondary: {
      main: "#00aeef",
    },
    background: {
      default: grey[200],
    },
  },
  ...themeBase,
};

export default createTheme(theme);
