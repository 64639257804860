import styled from "styled-components";

export default styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: ${({ theme }) => `${theme.spacing(1)}px`};
  transform-origin: 50% 50%;
  padding: ${({ theme }) => `0 0 ${theme.spacing(2)}px 0`};
  min-width: 300px;
  text-align: left;
`;
